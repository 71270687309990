// import $ from "jquery"; // Import jQuery

// $(window).on("load", function () {
//   $("body").addClass("page-loaded");

//   var loader = document.getElementById("loader")
//   setTimeout(() => {
//     loader.style.display="none"
//   }, 1500);
//   console.log("loaded"); // Added a console log for demonstration
// });

import React from "react";
import { toAbsoluteUrl } from "../../helpers/AssetHelpers";

const PreLoader = () => {
  return (
    <div className="preloader" id="loader">
      <div className="container">
        
        <img src={toAbsoluteUrl("media/loadergif.gif")} width={"100%"} alt="Loading..." />
      </div>
    </div>
  );
};

export default PreLoader;
