import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ src, height, width, onPlayPause }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePlay = () => {
    onPlayPause(true); // Notify parent that the video is playing
  };

  const handlePause = () => {
    onPlayPause(false); // Notify parent that the video is paused
  };

  return (
    <div className="video_component">
      <ReactPlayer
        url={src}
        muted
        controls
        width={width}
        height={height}
        onPlay={handlePlay}  // Set the play handler
        onPause={handlePause} // Set the pause handler
        style={{
          objectFit: "cover",
          objectPosition: "50% 50%",
          marginTop:
            screenSize < 450
              ? "-3rem"
              : screenSize < 872
              ? "-2.4rem"
              : screenSize < 1079
              ? "-2rem"
              : "-2rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  );
};

export default VideoPlayer;
