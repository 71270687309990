import React, { useState, useEffect, useRef } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

import { motion } from "framer-motion";
import { ImageGallery } from "react-image-grid-gallery";
import VideoPlayer from "./components/VideoComponent";

import image1 from "../assets/images/magicalmoments/image1.png";
import image2 from "../assets/images/magicalmoments/image2.png";
import image3 from "../assets/images/magicalmoments/image3.png";
import image4 from "../assets/images/magicalmoments/image4.png";
import image5 from "../assets/images/magicalmoments/image5.png";
import image6 from "../assets/images/magicalmoments/image6.png";
import image7 from "../assets/images/magicalmoments/image7.png";
import image8 from "../assets/images/magicalmoments/image8.png";
import image9 from "../assets/images/magicalmoments/image9.png";
import image10 from "../assets/images/magicalmoments/image10.png";
import image11 from "../assets/images/magicalmoments/image11.png";
import image12 from "../assets/images/magicalmoments/image12.png";
import image13 from "../assets/images/magicalmoments/image13.png";
import image14 from "../assets/images/magicalmoments/image14.png";
import image15 from "../assets/images/magicalmoments/image15.png";
import image16 from "../assets/images/magicalmoments/image16.png";
import image17 from "../assets/images/magicalmoments/image17.png";
import image18 from "../assets/images/magicalmoments/image18.png";
import image19 from "../assets/images/magicalmoments/image19.png";
import image20 from "../assets/images/magicalmoments/image20.png";
import image21 from "../assets/images/magicalmoments/image21.png";
import image22 from "../assets/images/magicalmoments/image22.png";
import image23 from "../assets/images/magicalmoments/image23.png";
import image24 from "../assets/images/magicalmoments/image24.png";
import image25 from "../assets/images/magicalmoments/image25.png";
import image26 from "../assets/images/magicalmoments/image26.png";
import image27 from "../assets/images/magicalmoments/image27.png";
import image28 from "../assets/images/magicalmoments/image28.png";
import image29 from "../assets/images/magicalmoments/image29.png";
import image30 from "../assets/images/magicalmoments/image30.png";

import video1 from "../assets/images/magicalmoments/video1.mp4";
import video2 from "../assets/images/magicalmoments/video2.mp4";
import video3 from "../assets/images/magicalmoments/video3.mp4";
import video4 from "../assets/images/magicalmoments/video4.MP4";
import video5 from "../assets/images/magicalmoments/video5.MP4";
import video6 from "../assets/images/magicalmoments/video6.MP4";
import video7 from "../assets/images/magicalmoments/video7.MP4";
import video8 from "../assets/images/magicalmoments/video8.MP4";

import happymoment1 from "../assets/images/newsevents/eventimage1.png";
import happymoment2 from "../assets/images/newsevents/eventimage2.png";
import happymoment3 from "../assets/images/new_arrival/image1.png";
import happymoment4 from "../assets/images/new_arrival/image2.png";
import happymoment5 from "../assets/images/new_arrival/image3.png";
import happymoment6 from "../assets/images/new_arrival/image4.png";
import happymoment7 from "../assets/images/connectwithus/image1.png";
import happymoment8 from "../assets/images/connectwithus/image2.png";
import happymoment9 from "../assets/images/connectwithus/image3.png";
import happymoment10 from "../assets/images/connectwithus/image4.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const MagicalMomentsWrapper = () => {
  const [width, setWidth] = useState(calculateWidth(window.innerWidth));
  const [isAutoplay, setIsAutoplay] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  var columnWidth = width;
  const sliderRef = useRef(null); // Ref for the slider instance


  // Callback function to set the play/pause state
  const handlePlayPause = (isPlaying) => {
    setIsVideoPlaying(isPlaying);
    setIsAutoplay(!isPlaying);
    
  };
  
  useEffect(() => {
    if (sliderRef.current) {
      if (isAutoplay) {
        sliderRef.current.slickPlay(); // Start autoplay
      } else {
        sliderRef.current.slickPause(); // Pause autoplay
      }
    }
  }, [isAutoplay]);

  const settings = {
    slidesPerRow: 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    autoplay: isAutoplay,
    autoplaySpeed: 2000, // Set the speed (in milliseconds)
    responsive: [
      {
        breakpoint: 1453,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1127,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 743,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(calculateWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateWidth(width) {
    if (width > 1085 && width < 1425) {
      return 230;
    } else if (width > 550 && width < 1085) {
      return 230;
    } else if (width < 550) {
      return 130;
    } else {
      return 330;
    }
  }

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const magicalMomentVideos = [
    { videos: video1 },
    { videos: video2 },
    { videos: video3 },
    { videos: video4 },
    { videos: video5 },
    { videos: video6 },
    { videos: video7 },
    { videos: video8 },
  ];

  const images2 = [
    { src: happymoment1 },
    { src: happymoment5 },
    { src: happymoment3 },
    { src: happymoment6 },
    { src: happymoment5 },
    { src: happymoment8 },
    { src: happymoment6 },
    { src: happymoment9 },
    { src: happymoment3 },
    { src: happymoment2 },
    { src: happymoment5 },
    { src: happymoment4 },
    { src: image7 },
    { src: happymoment10 },
    { src: happymoment6 },
    { src: image1 },
    { src: happymoment4 },
    { src: happymoment7 },
    { src: image6 },
    { src: happymoment4 },
    { src: image8 },
    { src: image18 },
    { src: image10 },
    { src: happymoment7 },
    { src: image11 },
    { src: image3 },
    { src: image5 },
    { src: image4 },
    { src: image9 },
    { src: image2 },
    { src: image12 },
    { src: image14 },
    { src: image15 },
    { src: image16 },
    { src: image17 },
    { src: image19 },
    { src: image13 },
    { src: image20 },
    { src: image21 },
    { src: image22 },
    { src: image23 },
    { src: image24 },
    { src: image25 },
    { src: image26 },
    { src: image28 },
    { src: image27 },

    { src: image29 },
    { src: image30 },
  ];

  const duplicatedImages = Array.from({ length: 14 }, () => [
    ...images2,
  ]).flat();

  const variants = {
    hidden: { opacity: 0, y: -50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, type: "spring", stiffness: 50 },
    }),
  };

  const variants2 = {
    hidden: { opacity: 0, x: -200 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  const animationVariants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: { opacity: 0, scale: 0.5, y: 100 },
  };

  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={variants2} // Apply the text animation
        className="d-flex justify-content-center main_title_news"
      >
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          className="text-center new_arrival_title"
        >
          Magical Moments with Rangoli
        </motion.p>
      </motion.div>

      <motion.div
        className="magical_moments_images_videos"
        style={{ marginTop: screenSize < 450 ? "1rem" : "" }}
      >
        {magicalMomentVideos.map((item, index) => (
          <motion.div
            key={index}
            custom={index}
            initial="hidden"
            animate={"hidden"} // Apply animation only if content is not loaded
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants}
          >
            <motion.div
              key={index}
              custom={index}
              initial="hidden"
              animate="visible"
              variants={variants}
            >
              <VideoPlayer
                src={item.videos}
                height={
                  screenSize < 450
                    ? "100%"
                    : screenSize < 872
                    ? "240px"
                    : screenSize < 1079
                    ? "300px"
                    : "300px"
                }
                width={
                  screenSize < 450
                    ? "100%"
                    : screenSize < 872
                    ? "300px"
                    : screenSize < 1079
                    ? "400px"
                    : "500px"
                }
              />
            </motion.div>
          </motion.div>
        ))}
      </motion.div>

      <Slider
      ref={sliderRef}
        {...settings}
        className="carousel_videos mt-5  d-none"
      >
        <VideoPlayer
          src={video1}
          height={"100%"}
          width={"100%"}
          onPlayPause={handlePlayPause} // Pass the callback to VideoPlayer
        />
        <VideoPlayer
          src={video2}
          height={"100%"}
          width={"100%"}
          onPlayPause={handlePlayPause} // Pass the callback to VideoPlayer
        />
        <VideoPlayer
          src={video3}
          height={"100%"}
          width={"100%"}
          onPlayPause={handlePlayPause} // Pass the callback to VideoPlayer
        />
        <VideoPlayer
          src={video4}
          height={"100%"}
          width={"100%"}
          onPlayPause={handlePlayPause} // Pass the callback to VideoPlayer
        />
        <VideoPlayer
          src={video5}
          height={"100%"}
          width={"100%"}
          onPlayPause={handlePlayPause} // Pass the callback to VideoPlayer
        />
        <VideoPlayer
          src={video6}
          height={"100%"}
          width={"100%"}
          onPlayPause={handlePlayPause} // Pass the callback to VideoPlayer
        />
        <VideoPlayer
          src={video7}
          height={"100%"}
          width={"100%"}
          onPlayPause={handlePlayPause} // Pass the callback to VideoPlayer
        />
        <VideoPlayer
          src={video8}
          height={"100%"}
          width={"100%"}
          onPlayPause={handlePlayPause} // Pass the callback to VideoPlayer
        />
        {/* Repeat VideoPlayer with other video sources if needed */}
      </Slider>

      <div className="magical_a">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants2}
          className="magical_gallery"
        >
          <ImageGallery
            imagesInfoArray={duplicatedImages} // Use the duplicated array for infinite effect
            columnCount={"auto"}
            columnWidth={columnWidth}
            gapSize={18}
          />
        </motion.div>
      </div>

      <Footer />
    </>
  );
};

const MagicalMoments = () => {
  return <Header element={<MagicalMomentsWrapper />} />;
};

export { MagicalMoments };
