import React  from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home?ver=1.1";
import { AboutUs } from "../pages/AboutUs?ver=1.1";
import { FranchiseInquiry } from "../pages/FranchiseInquiry?ver=1.1";
import { DeliciousDiscovery } from "../pages/DeliciousDiscovery?ver=1.1";
import { ConnectWthUs } from "../pages/ConnectWthUs?ver=1.1";
import { Career } from "../pages/Career?ver=1.1";
import { MagicalMoments } from "../pages/MagicalMoments?ver=1.1";
import { ErrorPage } from "../pages/ErrorPage";


const PrivateRoutes = () => {

  return (
    
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="aboutus" exact element={<AboutUs />} />
        <Route path="franchiseinquiry" exact element={<FranchiseInquiry />} />
        <Route
          path="deliciousdiscovery"
          exact
          element={<DeliciousDiscovery />}
        />
        <Route
          path="connectwithus"
          exact
          element={<ConnectWthUs />}
        />
        <Route
          path="career"
          exact
          element={<Career />}
        />
        <Route
          path="magicalmoments"
          exact
          element={<MagicalMoments />}
        />
        {/* <Route
          path="*"
          exact
          element={<ErrorPage />}
        /> */}

      </Routes>
  );
};

export { PrivateRoutes };
