// // first withj proper start but no bounce hover

// import React from "react";
// import { motion } from "framer-motion";

// const AnimatedText = ({ text }) => {
//   // Splitting text into individual letters
//   const letters = Array.from(text);

//   // Variants for each letter
//   const letterAnimation = {
//     hidden: { opacity: 0, y: 50 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         type: "spring",
//         stiffness: 300,
//         damping: 20,
//       },
//     },
//   };

//   return (
//     <motion.div
//       style={{  overflow: "hidden" , display : "inline"  }}
//       initial="hidden"
//       whileInView="visible"
//       viewport={{ once: true }}
//       transition={{ staggerChildren: 0.05 }}
//     >
//       {letters.map((letter, index) => (
//         <motion.span key={index} variants={letterAnimation}>
//           {letter === " " ? "\u00A0" : letter}
//         </motion.span>
//       ))}
//     </motion.div>
//   );
// };

// export default AnimatedText;

import React from "react";
import { motion } from "framer-motion";

const AnimatedText = ({ text }) => {
  // Splitting text into individual letters
  const letters = Array.from(text);

  // Variants for each letter
  const letterAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
  };

  return (
    <motion.div
      style={{
        display: "block", // Change to block to allow wrapping
        whiteSpace: "normal", // Allows text to wrap
        wordBreak: "break-all", // Breaks long words
      }}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ staggerChildren: 0.05 }}
    >
      {letters.map((letter, index) => (
        <motion.span key={index} variants={letterAnimation}>
          {letter === " " ? "\u00A0" : letter}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default AnimatedText;
  




